// Constants (from v2)
const contentWidth = 1180;
const mobileThreshold = 1180;
const mobileThresholdPixels = `${mobileThreshold}px`;
const smallMobileThresholdPixels = `${mobileThreshold / 2}px`;
const smallDesktopThresholdPixels = '1300px';
const menuBarHeight = 55;
const menuBarHeightWithBanner = 130;
const menuBarHeightPixels = `${menuBarHeight}px`;
const menuBarHeightWithBannerPixels = `${menuBarHeightWithBanner}px`;
const mobileMenuBarHeight = 45;
const mobileMenuBarHeightWithBanner = 130;
const mobileMenuBarHeightPixels = `${mobileMenuBarHeight}px`;
const mobileMenuBarHeightWithBannerPixels = `${mobileMenuBarHeightWithBanner}px`;

// margins (from v2)
const margins = {
  xs: '7px',
  s: '15px',
  m: '30px',
  l: '44px',
  xl: '88px',
};

// fontSizes (from v3)
const fontSizes = {
  xs: '12px',
  s: '15px',
  m: '18px',
  l: '24px',
  xl: '30px',
  x2l: '36px',
  x3l: '46px',
  x4l: '50px',
};

// colors (from v2 and v3)
const colors = {
  navy: '#172c42',
  navyTransparent: 'rgba(23,44,66,0.95)',
  lightGrey: '#F7F7F7',
  linen: '#F0F2F4',
  grey: '#777',
  darkGrey: '#787878',
  lightBlue: '#3B68B0',
  purpleGrey: '#F7F6FC',
  yellow: '#F5C946',
  white: '#FFFFFF',
  black: '#000',
  blueGrey: '#DFE4EF',
  darkBlueGrey: '#7F8FA4',
  lightGrey2: '#E5E5E5',
  lightGrey3: '#C4C4C4',
  lightGrey4: 'rgba(23, 44, 66, 0.4)',
  lightGrey5: '#FBFBFB',
  lightGrey6: '#BBBBBB',
  lightGrey7: 'rgba(247, 247, 247, 1)',
  red: '#FF0000',
  popup: 'rgba(23, 44, 66, 0.72)',
  opacityFilter: 'rgba(0, 0, 0, 0.25)',
  myAccountMenu: 'rgba(0, 0, 0, 0.1)',
  shadowGiftBanner: 'rgba(0, 0, 0, 0.05)',
  whiteTransparent: 'rgba(255,255,255,0.95)',
  whiteTransparent2: 'rgba(255, 255, 255, 0.7)',
  lightBlue2: '#F5F8FF',
  mediumGrey: '#F0F2F4',
  lightBlue3: '#FCFDFF',
  yellow2: '#EEC114',
  lightPink: '#E6CFC5',
  blue: '#638BB9',
  darkGrey2: '#7E7E7E',
  shadow: 'rgba(223, 228, 239, 0.5)',
  green: '#B7C7C4',
  greenShadow: 'rgba(183, 199, 196, 0.3)',
  error: '#FF3A44',
};

export default {
  contentWidth,
  mobileThreshold,
  mobileThresholdPixels,
  smallMobileThresholdPixels,
  smallDesktopThresholdPixels,
  menuBarHeight,
  menuBarHeightWithBanner,
  menuBarHeightPixels,
  menuBarHeightWithBannerPixels,
  mobileMenuBarHeight,
  mobileMenuBarHeightWithBanner,
  mobileMenuBarHeightPixels,
  mobileMenuBarHeightWithBannerPixels,
  margins,
  colors,
  fontSizes,
};
