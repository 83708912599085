import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import withOrderContext from '../withOrderContext';
import { pushToLayer, getOrderEvents } from '../services/googleTagManager';
import { Button } from './home/v3/styledComponents';

export function pushOrderStartToGTM(orderRoute, fromInteraction, restart) {
  pushToLayer({
    event: 'Order Funnel Started',
    from: window.location.pathname,
    from_interaction: fromInteraction,
    funnel_type: 'standard',
    restart: restart ? 1 : 0,
  });
  if (restart) {
    const events = getOrderEvents() || [];
    events.forEach((event) => pushToLayer(event, true));
  }
}

const CTALink = ({
  children, fromInteraction, defaultLabel, large, transparent, linkState,
  orderContext: { getOrderRouteAndEvent },
}) => {
  const { restart, orderRoute } = getOrderRouteAndEvent();
  const label = !restart || fromInteraction !== 'Pricings'
    ? defaultLabel
    : 'Revenir vers le parcours';
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={orderRoute}
      state={linkState}
      onClick={() => pushOrderStartToGTM(orderRoute, fromInteraction, restart)}
    >
      {!children
        ? <Button navy={!transparent} large={large}>{label}</Button>
        : React.cloneElement(children, { label })}
    </Link>
  );
};

CTALink.propTypes = {
  fromInteraction: PropTypes.string.isRequired,
  children: PropTypes.node,
  orderContext: PropTypes.shape({
    getOrderRouteAndEvent: PropTypes.func,
  }).isRequired,
  defaultLabel: PropTypes.string,
  large: PropTypes.bool,
  transparent: PropTypes.bool,
  linkState: PropTypes.shape({}),
};

CTALink.defaultProps = {
  children: undefined,
  large: false,
  transparent: false,
  defaultLabel: 'Prendre rendez-vous',
  linkState: {},
};

export default withOrderContext(CTALink);
